import React from "react";
import Layout from "@/components/Layout";
import { graphql } from "gatsby";
import SEO from "@/components/seo";
import { useLocation } from "@reach/router";
import Breadcrumb from "@/components/Common/Breadcrumb";
import { type ServiceProps, type ServiceCategory, type Service } from "@/types";
import Details from "@/templates/Details";
import Partners from "@/components/Home/Partners";

const SingleService: React.FC<ServiceProps> = ({ data }) => {
  const { pathname } = useLocation();
  const category: ServiceCategory = data.allServicesJson.edges[0].node;
  const slugService = pathname.split("/")[2];
  const service: Service = category?.services?.filter(
    (service) => service.slug === slugService,
  )[0];

  return (
    <Layout>
      <SEO
        title={service != null ? service.name : ""}
        canonical={`${useLocation().host}`}
      />
      <Breadcrumb
        name={service != null ? service.name : ""}
        pathName={service != null ? service.name : ""}
        imgUrl="/images/fotter-sub.jpeg"
      />
      <Details service={service} />
      <Partners />
    </Layout>
  );
};

export default SingleService;

export const query = graphql`
  query SingleService($slug: String!) {
    allServicesJson(
      filter: { services: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          name
          image
          description
          services {
            name
            imageTop
            imageTopName
            imageTop1
            imageBottom
            imageBottom1
            featuredImages
            slug
            details {
              image
              content
              contentTitle
              contentSubtitle
              items
              additionalItems
              contactInfo
              productAdvantages
              certifications
              services
              companies {
                url
                name
                location
              }
              videos {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;
