import React, { useEffect, useState } from "react";
import CloseIcon from "@/components/Capabilities/Modal/CloseIcon";

interface Props {
  imageUrl?: string | null;
  closeModal: () => void;
}

const ImageModal: React.FC<Props> = ({ imageUrl, closeModal }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <div className="modal-background">
        <div className="modal-container">
          <div className="modal-content-wrapper">
            <div className="modal-content">
              <img
                src={String(imageUrl)}
                alt="Locations Image"
                onLoad={() => {
                  setLoaded(true);
                }}
                className={`${loaded ? "loaded" : ""}`}
              />
            </div>
            <div className="modal-close" onClick={closeModal}>
              <CloseIcon onClick={closeModal} size={32} color="white" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageModal;
