import { type Service } from "@/types";
import React, { useState } from "react";
import ImageModal from "@/components/Capabilities/Modal";
import { Link } from "gatsby";

interface Props {
  service: Service;
}

const onlyVideos = ["airfi-videos", "satto-videos", "mx14-videos"];

const Details: React.FC<Props> = ({ service }) => {
  const {
    imageTop,
    imageTop1,
    imageTopName,
    imageBottom,
    imageBottom1,
    slug,
    featuredImages,
  } = service;

  const isGalleyEquipmentPath = slug === "galley-equipment";
  const isAirfiLeoPath = slug === "airfi-leo";
  const isHandrailsPath = slug === "handrails";
  const isBeforeAfter = slug === "shrouds" || slug === "trash-galley-bins";
  const isAmagPath = slug === "amag";

  const isImageBottom1 = imageBottom1 !== null;

  const getImagePath = (image: string) => {
    if (image.includes("https")) {
      return image;
    } else {
      return `/images/service/${String(image)}`;
    }
  };

  const formatEmailAsLink = (textWithEmail: string) => {
    const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;
    const match = textWithEmail.match(emailRegex);
    if (match == null) {
      return <>{textWithEmail}</>;
    }
    const [textBeforeEmail, textAfterEmail] = textWithEmail.split(emailRegex);
    const email = match[0];

    return (
      <>
        {textBeforeEmail}
        <a href={`mailto:${email}`}>{email}</a>
        {textAfterEmail}
      </>
    );
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImageUrl(imageUrl);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImageUrl(null);
    setModalOpen(false);
  };

  return (
    <div className="rts-single-service-wrapper rts-bg pt--0">
      <div className="container pt--40">
        <div className="content-wp bg-light pt--80">
          <div
            className={`row justify-content-between single-content-wp${
              !isAirfiLeoPath ? " g-65" : ""
            }`}
          >
            {imageTop !== null && (
              <div
                className={isAirfiLeoPath ? "col-12 text-center" : "col-md-5"}
              >
                {imageTop !== null && imageTop1 === null && (
                  <div className="row image-wrapper-img-top">
                    <div
                      className={`col-12${
                        isHandrailsPath ? " before-after" : ""
                      }`}
                    >
                      <img
                        src={`/images/service/${String(imageTop)}`}
                        alt="Service Image"
                      />
                      <span>{imageTopName}</span>
                    </div>
                  </div>
                )}
                {imageTop !== null && imageTop1 !== null && (
                  <div className="image-wrapper col-md-12">
                    <div className="row">
                      <div
                        className={`${isAirfiLeoPath ? "col-md-6" : "col-12"}${
                          isBeforeAfter ? " before" : ""
                        }`}
                      >
                        {!isAmagPath ? (
                          <img
                            style={{ height: "100%", objectFit: "cover" }}
                            src={`/images/service/${String(imageTop)}`}
                            alt="Service Image"
                          />
                        ) : (
                          <>
                            <img
                              style={{
                                height: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              src={`/images/service/${String(imageTop)}`}
                              alt="Service Image"
                              onClick={() => {
                                handleImageClick(
                                  `/images/service/${String(imageTop)}`,
                                );
                              }}
                            />
                            {modalOpen && (
                              <ImageModal
                                imageUrl={selectedImageUrl}
                                closeModal={handleCloseModal}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className={`${!isAirfiLeoPath ? "mt--24" : ""}${
                          isAirfiLeoPath ? " col-md-6" : " col-12"
                        }${isBeforeAfter ? " after" : ""}`}
                      >
                        {!isAmagPath ? (
                          <img
                            style={{ height: "100%", objectFit: "cover" }}
                            src={`/images/service/${String(imageTop1)}`}
                            alt="Service Image"
                          />
                        ) : (
                          <>
                            <img
                              style={{
                                height: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              src={`/images/service/${String(imageTop)}`}
                              alt="Service Image"
                              onClick={() => {
                                handleImageClick(
                                  `/images/service/${String(imageTop)}`,
                                );
                              }}
                            />
                            {modalOpen && (
                              <ImageModal
                                imageUrl={selectedImageUrl}
                                closeModal={handleCloseModal}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {service.details.map(
                  (detail) =>
                    detail.productAdvantages !== null &&
                    detail.videos?.map(({ id }) => (
                      <div
                        key={id}
                        className={`row ${
                          !onlyVideos.includes(slug) ? "mt--24" : ""
                        }`}
                      >
                        <div className="col-12">
                          <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube-nocookie.com/embed/${id}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          ></iframe>
                        </div>
                      </div>
                    )),
                )}
              </div>
            )}

            <div
              className={`col${
                onlyVideos.includes(slug) || isAirfiLeoPath
                  ? " col-12"
                  : " d-flex align-items-center flex-column justify-content-center"
              }`}
            >
              {service.details?.map((detail, index) => (
                <div key={`detail-${index}`} className="row">
                  {(detail.content !== null || detail.items !== null) && (
                    <>
                      {detail.image !== null && detail.image !== undefined && (
                        <div className="col-md-6">
                          <img
                            src={getImagePath(String(detail.image))}
                            alt="Service Image"
                          />
                        </div>
                      )}
                      <div className="col">
                        {detail.contentTitle !== null && (
                          <h6 className="cap-title d-flex justify-content-start">
                            {detail.contentTitle}
                          </h6>
                        )}
                        {detail.contentSubtitle !== null && (
                          <p className="bold">{detail.contentSubtitle}</p>
                        )}
                        {detail.content?.map((c, index) => (
                          <p key={`content-${index}`}>{c}</p>
                        ))}

                        {detail.items !== null && (
                          <ul>
                            {detail.items?.map((item, index) => (
                              <li key={`item-${index}`}>{item}</li>
                            ))}
                          </ul>
                        )}

                        {detail.additionalItems !== null && (
                          <>
                            <p className="bold">Additional Capabilities</p>
                            <ul>
                              {detail.additionalItems?.map((addItem, index) => (
                                <li key={`additional-item-${index}`}>
                                  {addItem}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}

                        {detail.contactInfo !== null && (
                          <ul>
                            <li>{formatEmailAsLink(detail.contactInfo)}</li>
                          </ul>
                        )}
                      </div>
                    </>
                  )}

                  {detail.productAdvantages !== null && (
                    <div>
                      <h6 className="cap-title d-flex justify-content-start">
                        Product Advantages:
                      </h6>
                      <ul>
                        {detail.productAdvantages?.map((item, index) => (
                          <li key={`pa-${index}`}>{item}</li>
                        ))}
                        <li>
                          {detail.contactInfo !== null &&
                            formatEmailAsLink(detail.contactInfo)}
                        </li>
                      </ul>
                    </div>
                  )}

                  {detail.certifications !== null && (
                    <div className="col-12">
                      <h6 className="cap-title d-flex justify-content-start">
                        APPROVALS & CERTIFICATIONS:
                      </h6>
                      <ul>
                        {detail.certifications?.map((cert, index) => (
                          <li key={`certification-${index}`}>{cert}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {detail.companies !== null && (
                    <>
                      <p>We are comprised of the following companies:</p>

                      {detail.companies?.map(({ url, name, location }) => (
                        <div key={url} className="companies-wp">
                          <p className="cap-title d-flex justify-content-start">
                            {name}
                          </p>
                          <p> {location}</p>
                          <Link
                            to={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {url?.replace(/(http:\/\/|https:\/\/)/gi, "")}
                          </Link>
                        </div>
                      ))}
                    </>
                  )}

                  {detail.services !== null && (
                    <div className="services-wp">
                      <p className="bold">Services Include:</p>
                      <ul>
                        {detail.services?.map((serv, index) => (
                          <li key={`service-${index}`}>{serv}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {detail.videos !== null &&
                    detail.productAdvantages === null && (
                      <div className="row">
                        {detail.videos?.map((video) => (
                          <div
                            key={video.id}
                            className={`col${
                              onlyVideos.includes(slug) ? "-md-6" : ""
                            } mt--30`}
                          >
                            <iframe
                              width="560"
                              height="315"
                              src={`https://www.youtube-nocookie.com/embed/${video.id}`}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>

          {imageBottom !== null && (
            <div
              className={`row ${
                isImageBottom1 ? "bottom-wrapper" : "single-image-wp"
              }`}
            >
              <div
                className={
                  isImageBottom1
                    ? isGalleyEquipmentPath
                      ? "col-md-3 text-center"
                      : "col-md-6"
                    : "col-12"
                }
              >
                <img
                  src={getImagePath(String(imageBottom))}
                  alt="Service Image"
                  style={{ textAlign: "center", width: "auto" }}
                  className={`${!isGalleyEquipmentPath ? " w-100" : ""}`}
                />
              </div>
              {isImageBottom1 && (
                <div className={isGalleyEquipmentPath ? "col-md-9" : "col"}>
                  <img
                    src={getImagePath(String(imageBottom1))}
                    alt="Service Image"
                  />
                </div>
              )}
            </div>
          )}

          {featuredImages !== null && (
            <div
              className="col"
              style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
            >
              {featuredImages?.map((im, index) => (
                <img
                  key={`f-img-${index}`}
                  src={`/images/service/${im}`}
                  alt="Service Image"
                  style={{
                    height: "310px",
                    width: "auto",
                    objectFit: "cover",
                    flex: "1 1 0",
                  }}
                />
              ))}
            </div>
          )}
        </div>
        <hr className="cap-divider mb--0 mt--30" />
      </div>
    </div>
  );
};

export default Details;
