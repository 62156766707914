import React from "react";

interface Props {
  onClick: () => void;
  color?: string;
  size?: number;
}

const CloseIcon: React.FC<Props> = ({
  onClick,
  color = "black",
  size = 24,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      onClick={onClick}
    >
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
        d="M1 1l12 12M1 13L13 1"
      />
    </svg>
  );
};

export default CloseIcon;
